import { Box, LinearProgress } from "@mui/material";
import React, { Suspense } from "react";
import DateFnsAdapter from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ruLocale from "date-fns/locale/ru";
const MainModule = React.lazy(() => import(`../modules/${process.env.REACT_APP_MAIN_MODULE}/Module`));

interface IShellProps {}

export const Shell: React.FC<IShellProps> = (props) => {
  return (
    <Suspense fallback={<LinearProgress />}>
      <LocalizationProvider dateAdapter={DateFnsAdapter} locale={ruLocale}>
        <Box
          sx={{
            backgroundColor: "#171925",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <MainModule />
        </Box>
      </LocalizationProvider>
    </Suspense>
  );
};
